import { PropsWithChildren, useEffect, useState } from 'react'
import { useContentfulContext } from './ContentfulContext'
import { Button, Icon, Popover, PopoverContent, PopoverLauncher, tokens } from '@vp/swan'
import { areContentfulLinksEnabled, getContentfulLink, validateContentfulContext, validateContentfulMetadata } from './web/utils'

type ContentfulLinkProps = {
  id: string
}

export const ContentfulLink : React.FC<PropsWithChildren<ContentfulLinkProps>> = ({ children, id }) => {
  const contentfulContext = useContentfulContext()
  const enoughInfo = validateContentfulContext(contentfulContext) && validateContentfulMetadata({ contentful_id: id, contentfulName: 'NOT_USED' })
  const entryUrl = enoughInfo ? getContentfulLink(contentfulContext, id) : undefined

  const [show, setShow] = useState(false)
  useEffect(() => {
    if (areContentfulLinksEnabled() && entryUrl) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [entryUrl])

  if (!show) {
    return <>{children}</>
  }

  return (
    <div style={{ position: 'relative' }}>
      <Popover style={{ position: 'absolute', left: tokens.SwanSemSpace5, top: tokens.SwanSemSpace5, zIndex: tokens.SwanSemZIndexOverTheTop }}>
        <PopoverLauncher>
          <Button><Icon src={imageUrl} /></Button>
        </PopoverLauncher>
        <PopoverContent><a href={entryUrl} target='_blank' rel='noreferrer'>Go to contentful entry</a></PopoverContent>
      </Popover>
      {children}
    </div>
  )
}

const imageUrl = 'https://cms.cloudinary.vpsvc.com/image/upload/c_scale,w_24/v1726830243/page_icons/logos/contentful.png'
