import { ContentfulMetadata } from 'src/types'
import { ContentfulContextType } from '../ContentfulContext'

const SHOW_CONTENTFUL_LINKS = 'showContentfulLinks'
const DEBUG_PAGE = 'debugPage'

export function areContentfulLinksEnabled (): boolean {
  return window?.location?.search.includes(SHOW_CONTENTFUL_LINKS) ?? false
}

export function isDebugPageEnabled (): boolean {
  return window?.location?.search.includes(DEBUG_PAGE) ?? false
}

export function getContentfulLink (contentfulContext: ContentfulContextType, contentfulId: string) {
  if (!contentfulContext || !contentfulContext.space || !contentfulContext.environment) {
    return ''
  }
  return `https://app.contentful.com/spaces/${contentfulContext.space}/environments/${contentfulContext.environment}/entries/${contentfulId}`
}

export function validateContentfulContext (contentfulContext?: ContentfulContextType) : contentfulContext is ContentfulContextType {
  if (!contentfulContext || !contentfulContext.space || !contentfulContext.environment) {
    console.warn('ContentfulContextProvider is missing')
    return false
  }
  return true
}

export function validateContentfulMetadata (contentfulMetadata: ContentfulMetadata) : contentfulMetadata is ContentfulMetadata {
  if (!contentfulMetadata || !contentfulMetadata.contentful_id || !contentfulMetadata.contentfulName) {
    return false
  }

  return true
}
