import { BoundedContent, Card } from '@vp/swan'
import {
  areContentfulLinksEnabled,
  getContentfulLink,
  isDebugPageEnabled,
  validateContentfulContext,
  validateContentfulMetadata,
} from './web/utils'
import { useContentfulContext } from './ContentfulContext'
import { useEffect, useState, type PropsWithChildren } from 'react'
import type { DebugSectionProps } from './types'

export const DebugSection: React.FC<PropsWithChildren<DebugSectionProps>> = ({ contentfulMetadata, availability, search, children }) => {
  const contentfulContext = useContentfulContext()
  const enoughInfo = validateContentfulContext(contentfulContext) && validateContentfulMetadata(contentfulMetadata)
  const entryUrl = enoughInfo ? getContentfulLink(contentfulContext, contentfulMetadata.contentful_id) : undefined

  const [show, setShow] = useState(false)
  useEffect(() => {
    const debugModeEnabled = areContentfulLinksEnabled() || isDebugPageEnabled()
    if (debugModeEnabled && entryUrl) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [entryUrl])

  if (!show) {
    return <>{children}</>
  }

  return (
    <BoundedContent my={4}>
      <Card bgc='accent'>
        <h2>Debug information</h2>
        <ul>
          <li key='title'>
            Contentful Link:
            {contentfulContext && (
              <a href={getContentfulLink(contentfulContext, contentfulMetadata.contentful_id)}>
                {contentfulMetadata.contentfulName} (id: {contentfulMetadata.contentful_id})
              </a>
            )}
          </li>
          <li key='availability'>
            availability:
            <ul>
              {Object.keys(availability).map((key, index) => {
                const typedKey = key as keyof typeof availability
                return availability[typedKey]
                  ? (
                    <li key={`li_${index}`}>
                      {key}: {availability[typedKey]}
                    </li>
                    )
                  : (
                    <></>
                    )
              })}
            </ul>
          </li>
        </ul>
      </Card>
    </BoundedContent>
  )
}
