import { createContext, PropsWithChildren, useContext } from 'react'

export type ContentfulContextType = {
  space: string
  environment: string
}

const ContentfulContext = createContext<ContentfulContextType | undefined>(undefined)

type ContentfulContextProviderProps = {
  space: string
  environment?: string
}

export const ContentfulContextProvider: React.FC<PropsWithChildren<ContentfulContextProviderProps>> =
  ({ children, space, environment }) => {
    return (
      <ContentfulContext.Provider value={{ space, environment: environment ?? 'master' }}>
        {children}
      </ContentfulContext.Provider>
    )
  }

export const useContentfulContext = () => {
  const context = useContext(ContentfulContext)
  if (context === undefined) {
    console.warn('useContentfulContext must be used within a ContentfulContextProvider')
  }
  return context
}
